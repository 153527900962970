/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
import { css } from "@emotion/core"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import { fonts, mediaQueries, colors } from "../../styles"
import Layout from "../../components/layout"
import Footer from "../../components/Footer"
import SubNav from "../../components/SubNav/SubNav"

const parent = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(9, 300px);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  font-family: ${fonts.sans};
  .div1 {
    grid-area: 1 / 1 / 4 / 2;
  }
  .div2 {
    grid-area: 4 / 1 / 7 / 2;
  }
  .div3 {
    grid-area: 7 / 1 / 10 / 2;
  }
  .div4 {
    grid-area: 1 / 2 / 4 / 3;
  }
  .div5 {
    grid-area: 4 / 2 / 9 / 3;
  }
  .div6 {
    grid-area: 9 / 2 / 10 / 3;
  }
  ${mediaQueries.phoneLarge} {
    display: block;
  }
`

const linkStyle = css`
  color: ${colors.deepblue};
`
export default ({ data }) => {
  return (
    <Layout title="学部课程">
      <div css={parent}>
        {/* 1 */}
        <div
          className="div1"
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(228, 227, 226);
            ${mediaQueries.phoneLarge} {
              padding: 300px 0;
            }
          `}
        >
          <div>
            <SubNav />
            <h1
              css={css`
            font-family: ${fonts.sans};
            color: rgb(3,11,93);
            &:before {
              content: "";
              height: 33px;
              width: 33px;
              border-radius: 50%;
              position: relative;
              top: 4px;
              margin-right: 8px;
              background-color: rgb(3,11,93);
              display: inline-block;
          `}
            >
              课程-学部
            </h1>
          </div>
        </div>
        {/* 2 */}
        <div
          className="div2"
          css={css`
            font-size: 40px;
            color: white;
            padding: 20px;
            background-color: rgb(42, 42, 42);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            ${mediaQueries.phoneLarge} {
              display: none;
            }
          `}
        >
          <span>KIYOMIZU</span>
          <span>ART</span>
          <span>EDUCATION</span>
        </div>
        {/* 3 */}
        <div
          className="div3"
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(196, 195, 195);
          `}
        >
          <h1
            css={css`
            font-family: ${fonts.sans};
            color: rgb(44, 44, 44);
            ${mediaQueries.phoneLarge} {
              display: none;
            }
            &:before {
              content: "";
              height: 2rem;
              width: 2rem;
              border-radius: 50%;
              position: relative;
              top: 4px;
              margin-right: 8px;
              background-color: rgb(44, 44, 44);
              display: inline-block;
          `}
          >
            课程-学部
          </h1>
        </div>
        {/* 4 */}
        <div
          className="div4"
          css={css`
            background-color: rgb(3, 11, 93);
          `}
        >
          <ul
            css={css`
              display: flex;
              justify-content: space-around;
              font-size: 1.4rem;
              font-weight: 600;
              list-style: none;
              margin: 0;
              padding: 1rem 0;
              background-color: white;
              ${mediaQueries.phoneLarge} {
                display: none;
              }

              li {
                position: relative;
              }

              li:before {
                content: "";
                height: 1.4rem;
                width: 1.4rem;
                border-radius: 50%;
                border: 1px solid ${colors.deepblue};
                position: relative;
                top: 4px;
                margin-right: 8px;
                display: inline-block;
              }

              li.active:before {
                background-color: ${colors.deepblue};
              }

              ul {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                padding-left: 0;
                left: 0;
                top: 21px;
                display: none;
                background: white;
              }

              li:hover > ul,
              li ul:hover {
                visibility: visible;
                opacity: 1;
                display: block;
                min-width: 250px;
                text-align: left;
                box-shadow: 0px 3px 5px -1px #ccc;
              }

              li ul li {
                clear: both;
                width: 100%;
                text-align: left;
                padding: 10px;
                margin: 0;
                list-style: none;
                transition: all 0.5s ease;
              }

              li ul li:not(:first-of-type) {
                border-top: 1px solid gray;
              }

              li ui li:before {
                border-style: none;
              }

              li ul li:hover {
                transition: all 0.3s ease;
                cursor: pointer;
                color: white;
                background-color: rgb(1, 9, 90);

                &:before {
                  background-color: white;
                }
              }
            `}
          >
            <li>
              <Link to="/" css={linkStyle}>
                清水艺术
              </Link>
            </li>
            <li>
              <Link to="/about" css={linkStyle}>
                关于清水
              </Link>
            </li>
            <li>
              <Link to="/schools" css={linkStyle}>
                学校介绍
              </Link>
              <ul>
                <li>东京五美大</li>
                <li>关西三美大</li>
                <li>国公立五艺大</li>
                <li>综合类高排名院校</li>
                <li>其他院校</li>
              </ul>
            </li>
            <li className="active">
              课程 -{" "}
              <Link to="/class/normal" css={linkStyle}>
                学部
              </Link>
              ・
              <Link to="/class/master" css={linkStyle}>
                大学院
              </Link>
            </li>
          </ul>
          <div
            css={css`
              color: white;
              padding: 40px;
              font-size: 20px;
              font-weight: 600;
              p {
                line-height: 33px;
              }
              ${mediaQueries.phoneLarge} {
                p {
                  font-size: 14px;
                  line-height: 26px;
                }
                padding: 50px 20px;
              }
            `}
          >
            <p>
              学部的课程，主要分三个阶段，基础练习/复习，学科针对强化学习，最终辅导。
            </p>
            <p>
              第一阶段根据自身的程度来安排相应的基础阶段/复习阶段，比如素描课程，
              色彩类课程，人物练习课程等综合性课程。
            </p>
            <p>
              第二阶段会根据要考的学科进行专业学习，因日本考学的特点，不同学科，
              不同学校考学内容都不尽相同，所有根据这个特性要根据专业开始强化训练。
              或者作品集学校制作准备。
            </p>
            <p>
              第三阶段根据考前的综合实力来推荐所考学校，待同学目标确立后，
              进行最终的面试辅导，小论文辅导，志望理由书，
              这些都是除了美术考试之外要考核的重要内容。
            </p>
          </div>
        </div>
        {/* 5 */}
        <div className="div5">
          {data.allFile.edges.map(i => (
            <Img key={i.node.base} fluid={i.node.childImageSharp.fluid} />
          ))}
        </div>
        {/* 6 */}
        <div
          className="div6"
          css={css`
            background-color: rgb(44, 44, 44);
          `}
        ></div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "normal" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
